div.block-container {
  padding: 2rem 0;
  width: 100%;
  display: flex;
  >div {
    padding: 2rem; }
  @for $width from 1 to 12 {
    .col-#{$width} {
      width: calc(100% * #{$width} / 12);
      display: flex;
      justify-content: center;
      >div {
        display: flex;
        flex-direction: column;
        justify-content: center; } } } }
