div.App {
  background: black;
  color: white;
  div.container {
    max-width: 1280px;
    margin: auto; }
  h2 {
    font-size: 3rem;
    color: white;
    margin: 0 0 2rem; }
  p {
    font-size: 1.2rem;
    color: darken(white, 20%);
    margin: 0 0 1rem; }
  a {
    color: pink; } }

header.App-header {
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  background: black;
  color: white;
  div.center-block {
    width: fit-content;
    margin: auto;
    h1 {
      width: fit-content;
      margin: auto;
      text-align: center;
      font-size: 4rem;
      @media (max-width: 580px) {
        font-size: 3rem; } }
    p {
      font-size: 1.5rem;
      color: darken(white, 60%);
      display: block;
      margin: auto;
      width: fit-content;
      text-align: center;
      margin-top: 1rem; } } }

.flex-grid {
  display: flex; }
.col {
  flex: 1; }


